<template>
  <div class="big">
      <p class="title">{{data.name}}</p>
      <ul>
          <li>
              <span class="titleName">班级名称</span>
              <span class="tltlecontent">{{data.className}}&nbsp; &nbsp; {{data.semesterName}}</span>
          </li>
            <li>
              <span class="titleName">创建时间</span>
              <span class="tltlecontent">{{$timestampToDate(data.createTime)}}</span>
          </li>
            <li>
              <span class="titleName">截止日期</span>
              <span class="tltlecontent">{{$timestampToDate(data.endTime)}}</span>
          </li>
      </ul>
      <div class="ask">
          <div style="width:56px" class="titleName">作业要求</div>
          <div class="content">
              {{data.remarks}}
          </div>
      </div>
  </div>
</template>

<script>
import {train_taskDetail} from '../../../api/train';
export default {
    data() {
        return {
          data:{}
        };
    },
  created(){
      this.taskId = this.$route.query.id
      this.trainTaskDetail();
  },
  methods:{
      trainTaskDetail(){
          train_taskDetail(this.taskId).then(res=>{
              this.data=res.data;
              //父组件获取作业类型0音频，1视频
              this.$emit('getClassification',res.data.classification)
          })
      },
  },
  watch: {

  }
}
</script>

<style scoped lang="scss">
*{
    margin:0px;
    padding: 0px;
    list-style: none;
}
  .big{
      box-sizing: border-box;
      margin-top: 24px;
      width: 100%;
      background: #f8f8f8;
      padding:26px 0px 24px 30px;
      margin-bottom: 40px;
      .title{
          font-size: 16px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
      }
      ul{
          display: flex;
          margin: 20px 0px;
          >li{
              margin-right: 32px;
          }
      }
      .ask{
          display: flex;
        >.content{
            font-size: 14px;
            width:698px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #333333;
            line-height: 22px;
        }
      }
      .titleName{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #999999;
        margin-right: 16px;
        word-spacing: normal;
      }
      .tltlecontent{
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #333333;
      }
  }
</style>
