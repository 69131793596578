// 提交作业

<template>
  <div class="works">
    <LeftMenu current="taskrun"></LeftMenu>
    <div class="main">
      <el-row class="header_row">
        <el-col :span="12">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item to="/taskrun">未上交</el-breadcrumb-item>
            <el-breadcrumb-item>提交作业</el-breadcrumb-item>
          </el-breadcrumb>
        </el-col>
        <el-col :span="12" style="text-align: right"
          ><el-link type="primary" :underline="false" @click="$router.back()">
            返回</el-link
          ></el-col
        >
      </el-row>
      <el-divider class="border-bottom"></el-divider>
      <p class="header-title">提交作业</p>
      <Jobdetails @getClassification = "getClassification" />
      <div class="form">
        <el-form
            v-if="classification === 0"
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="24">
              <el-form-item label="作品名称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder="请输入您的作品名称"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="词作者" prop="writer">
                <el-input
                  v-model="ruleForm.writer"
                  placeholder="多个作者以+号连接，如：大白+小黑"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="曲作者" prop="composer">
                <el-input
                  v-model="ruleForm.composer"
                  placeholder="多个作者以+号连接，如：大白+小黑"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="音频文件" prop="opernPath">
                <el-upload
                  :action="uploadApi"
                  :headers="{ ...headers, fileType: 'audio' }"
                  :before-upload="beforeupload1"
                  :show-file-list="false"
                  accept=".mp3,.asf,.wma,.wav,.m4a"
                  :on-success="audioSuccess"
                >
                <!-- audio/mp3,audio/mpeg,audio/asf,audio/wma,audio/wav,audio/x-ms-wma,audio/m4a,audio/x-m4a,audio/x-ms-wma -->
                  <div v-show="ruleForm.opernPath === ''" class="upload-button">
                    <img
                      :src="'../../assets/upload-cloud.png'"
                      style="width: 16px; height: 16px"
                      alt=""
                    />
                    &nbsp;&nbsp;上传音频文件
                  </div>
                  <div v-show="ruleForm.opernPath !== ''" class="had-upload">
                    {{ ruleForm.opernPath.split("/").reverse()[0] }}
                    <div>
                      <img
                        src="../../assets/check-green.png"
                        width="10"
                        height="9"
                        alt="del"
                      />
                      <span>上传成功</span>
                      <img
                        src="../../assets/del.png"
                        width="18"
                        height="18"
                        alt="del"
                        @click="ruleForm.opernPath = ''"
                      />
                    </div>
                  </div>
                  <div slot="tip" class="el-upload__tip">
                    音频文件为MP3、ASF、WMA，WAV、M4A格式
                  </div>
                </el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="歌词文件" prop="lyrics">
                <el-upload
                  :action="uploadApi"
                  :headers="{ ...headers, fileType: 'lrc' }"
                  :before-upload="beforeUploadLyrics"
                  :show-file-list="false"
                  accept="text/plain"
                  :on-success="txtSuccess"
                >
                  <div v-show="ruleForm.lyrics === ''" class="upload-button">
                    <img
                      :src="'../../assets/upload-cloud.png'"
                      style="width: 16px; height: 16px"
                      alt=""
                    />
                    &nbsp;&nbsp;上传歌词文件
                  </div>
                  <div v-show="ruleForm.lyrics !== ''" class="had-upload">
                    {{ruleForm.lyrics.split("/").reverse()[0] }}
                    <div>
                      <img
                        src="../../assets/check-green.png"
                        width="10"
                        height="9"
                        alt="del"
                      />
                      <span>上传成功</span>
                      <img
                        src="../../assets/del.png"
                        width="18"
                        height="18"
                        alt="del"
                        @click="ruleForm.lyrics = ''"
                      />
                    </div>
                  </div>
                  <div slot="tip" class="el-upload__tip">歌词文件为TXT格式</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注说明" prop="remarks">
                <el-input
                  type="textarea"
                  :rows="6"
                  placeholder="说点儿什么..."
                  maxlength="300"
                  show-word-limit
                  style="font-size: 14px; line-height: 20px"
                  v-model="ruleForm.remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-row type="flex" justify="center">
              <el-col :span="12" style="text-align: center"
                ><el-button  @click="$router.back()">返回</el-button
                ><el-button class="active" @click="submitForm('ruleForm')"
                  >提交作品</el-button
                ></el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
        <el-form  label-position="top"
                  :model="videoForm"
                  :rules="rulesVideo"
                   ref="videoForm"
                  v-else>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="作品名称" prop="name">
               <el-input  placeholder="请输入作品名称" v-model="videoForm.name" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="主演" prop="lead">
                <el-input  placeholder="多位主演以+号连接，如：大白+小黑" v-model="videoForm.lead"  ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="导演" prop="director"  >
                <el-input  v-model="videoForm.director" placeholder="多位导演以+号连接，如：大白+小黑" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="策划" prop="plan">
                <el-input  placeholder="多位策划以+号连接，如：大白+小黑" v-model="videoForm.plan"  ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="摄像" prop="camera">
                <el-input  placeholder="多位摄像以+号连接，如：大白+小黑" v-model="videoForm.camera" ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="剪辑" prop="lip">
                <el-input  placeholder="多位剪辑以+号连接，如：大白+小黑" v-model="videoForm.lip" ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="40">
            <el-col :span="12">
              <el-form-item label="服化道" prop="clothing" >
                <el-input  placeholder="多个人名以+号连接，如：大白+小黑" v-model="videoForm.clothing"  ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="视频文件" prop="videoFile">
                <el-upload
                    :show-file-list="false"
                    class="upload-demo"
                    :action="uploadApi"
                    :headers="{...headers,fileType:'video'}"
                    :before-upload="beforeVideo"
                    accept=".mp4,.mov,.m4v,"
                    :on-success="succesVideo"
                    :on-progress="progress"
                    :multiple="false"
                    :disabled="updataReally"
                    >
                  <div   v-show="videoForm.videoFile === '' && !updataReally" class="upload-button">
                    <img
                        :src="'../../assets/upload-cloud.png'"
                        style="width: 16px; height: 16px"
                        alt=""
                    />
                    &nbsp;&nbsp;上传视频文件
                  </div>
                  <div v-show="videoForm.videoFile !== '' && !updataReally"  class="had-upload">
                  <span class="success-title">
                      {{videoForm.videoFile.split("/").reverse()[0] }}
                  </span>
                    <div>
                      <img
                          src="../../assets/check-green.png"
                          width="10"
                          height="9"
                          alt="del"
                      />
                      <span>上传成功</span>
                      <img
                          src="../../assets/del.png"
                          width="18"
                          height="18"
                          alt="del"
                          @click="videoForm.videoFile = ''"
                      />
                    </div>
                  </div>
                  <el-progress class="progress" v-show="updataReally" :percentage="speed"></el-progress>
                  <div slot="tip" class="el-upload__tip">视频文件需为mp4、mov、m4v格式，文件大小限制在200MB内</div>
                </el-upload>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item label="备注说明">
                <el-input
                    type="textarea"
                    :rows="6"
                    placeholder="说点儿什么..."
                    maxlength="300"
                    show-word-limit
                    style="font-size: 14px; line-height: 20px"
                    v-model="videoForm.remarks"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-row type="flex" justify="center">
              <el-col :span="12" style="text-align: center"
              ><el-button  @click="$router.back()">返回</el-button
              ><el-button class="active" @click="videoSubmit()"
              >提交作品</el-button
              ></el-col
              >
            </el-row>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import LeftMenu from '../../components/LeftMenu.vue'
import Jobdetails from './components/jobdetails.vue'
import store from '../../store/index'
import { train_submitTask,train_submitVideoTask } from '../../api/train'
export default {
  components: {
    LeftMenu,
    Jobdetails
  },

  data () {
    return {
      headers: {
        'X-EFRESH-SECRET': store.state.secret,
        'X-EFRESH-SESSION': store.state.session
      },
      uploadApi: process.env.VUE_APP_BASE_API + '/api/file/upload',
      //歌曲
      ruleForm: {
        name: '',// 歌曲名称
        writer: '',//词作者
        composer: '',//曲作者
        opernPath: '',//歌曲路径
        lyrics: '',//歌词路径
        remarks: ''//备注说明
      },
      //视频
    videoForm:{
       name:'',
       lead:'',
      director:'',
      plan:'',
      camera:'',
      lip:'',
      clothing:'',
      videoFile:'',
      remarks:''
    },
      //上传列表
      fileList:[],
      // 进度
      speed:0,
      //视频是否上传中 false 否
      updataReally:false,
      submitFlay:true,
      //判断是音频作业还是视频作业
      classification:0,
      rules: {
        name: { required: true, message: '请输入您的作品名称', trigger: 'blur' },
        writer: { required: true, message: '词作者姓名不能为空', trigger: 'blur' },
        composer: { required: true, message: '曲作者姓名不能为空', trigger: 'blur' },
        opernPath: { required: true, message: '音频文件不能为空', trigger: 'blur' },
        lyrics: { required: true, message: '歌词文件不能为空', trigger: 'blur' }
      },
      rulesVideo:{
        name:{required:true,message:'请输入您的作品名称',trigger:'blur'},
        lead:{required:true,message:'请输入主演人员姓名',trigger:'blur'},
        director:{required:true,message:'请输入导演人员姓名',trigger:'blur'},
        plan:{required:true,message:'请输入策划人员姓名',trigger:'blur'},
        camera:{required:true,message:'请输入摄像人员姓名',trigger:'blur'},
        lip:{required:true,message:'请输入剪辑人员姓名',trigger:'blur'},
        clothing:{required:true,message:'请输入服化道人员姓名',trigger:'blur'},
        videoFile:{required:true,message:'请输入视频文件',trigger:'blur'},
      }
    }
  },
  methods: {
    beforeupload1 (files) {
        // console.log(files)
      let list = ['mp3','asf','wma','wav','m4a']
      let index=files.name.lastIndexOf('.')
      let nametype=files.name.substring(index+1,files.name.length)
      console.log(nametype,'文件类型')
      if (list.indexOf(nametype) > -1) {
        return true
      } else {
        this.$popup({ msg: '上传文件格式不正确', type: 'warning' })
         this.ruleForm.opernPath=""
        return false
      }
    },
    beforeUploadLyrics (files) {
      if (files.type === 'text/plain') {
        return true
      } else {
        this.$popup({ msg: '上传文件格式不正确', type: 'warning' })
        this.ruleForm.lyrics=''
        return false
      }
    },
    txtSuccess (file) {
      this.ruleForm.lyrics = file.url
      this.$refs.ruleForm.clearValidate('lyrics')
    },
    audioSuccess (file) {
        if(file.code !==0){
              this.$popup({ msg: file.msg, type: 'warning' })
        }else{
             this.ruleForm.opernPath = file.url;
      this.$refs.ruleForm.clearValidate('opernPath')
        }

    },
    //  提交作品
    submitForm (formName) {
        if(!this.submitFlay){
            return ""
        }
     this.submitFlay=false;
      this.$refs[formName].validate(valid => {
        if (valid) {
          /* accompanyPath伴奏文件地址 composer作曲人 id提交作品id, 为空新增, 不为空修改 lrc歌词 lrcPath歌词文件地址 lyricist作词人 name歌曲名 remarks备注
          singer歌手名 songPath歌曲文件地址 taskId作业id */
          let r=this.ruleForm
          let p = {
               id:'',
               name:r.name,
               lyricist:r.writer,
               composer:r.composer,
               songPath:r.opernPath,
               lrcPath:r.lyrics,
               remarks:r.remarks,
               taskId:this.$route.query.id
          }
          train_submitTask(p).then(res => {
            if(res.code==0){
               this.$refs[formName].resetFields()
                this.$router.push('/submitted-success')
            }
            this.submitFlay=true;
          })
        }else{
             this.submitFlay=true;
        }
      })
    },
      /***
       * 上传提交视频
       * cameraman  string
       * allowEmptyValue: false
       * 摄像
       *
       * director  string
       * allowEmptyValue: false
       * 导演
       *
       * editor  string
       * allowEmptyValue: false
       * 剪辑
       *
       * fuhuadao  string
       * allowEmptyValue: false
       * 服化道
       *
       * name  string
       * allowEmptyValue: false
       * 作品名称
       *
       * performer  string
       * allowEmptyValue: false
       * 演员
       *
       * plan  string
       * allowEmptyValue: false
       * 策划
       *
       * remarks  string
       * allowEmptyValue: false
       * 备注
       *
       * taskId  integer($int64)
       * allowEmptyValue: false
       * 作业id
       *
       * videoPath
       */
    videoSubmit(){
         this.$refs.videoForm.validate(valid=>{
           if(valid){
             let v= this.videoForm
             let p ={
               cameraman:v.camera,
               director:v.director,
               editor	:v.lip,
               fuhuadao	:v.clothing,
               name:v.name,
               performer:v.lead,
               plan:v.plan,
               remarks:v.remarks,
               taskId	:this.$route.query.id,
               videoPath:v.videoFile
             }
             train_submitVideoTask(p).then(res=>{
               console.log(res)
               if(res.code === 0){
                 this.$router.push('/submitted-success')
               }
             })
           }
         })
      },
    //视频上传前
    beforeVideo(file){
      let typeArr = ['mp4', 'mov', 'm4v']
      let index=file.name.lastIndexOf('.')
      let type = file.name.substring(index+1, file.name.length)
      if(typeArr.indexOf(type) > -1){
        const isLt2M = file.size / 1024 / 1024 < 200
        if(isLt2M){
          this.updataReally = true;
          return true
        }else {
          this.$popup({msg:'您的文件已经超过200MB!'})
        }

      } else {
        this.$popup({msg:'您上传的视频格式不正确'})
        return false
      }
    },
  // 视频上传成功的
    succesVideo(file){
      this.speed = 0
      this.updataReally = false;
      if(file.code === 0){
        this.videoForm.videoFile = file.url
        this.$refs.videoForm.clearValidate('videoFile')
      } else {
        this.$popup({msg:file.msg})
      }
    },
    //上传进度
    progress(event){
      this.speed = parseInt(event.percent) - 1
    },
    //判断是否为视频
    getClassification(val){
      this.classification = val
    },
  },
  watch:{
   'ruleForm.lyrics'(newv){
    //  避免浏览器报错: Cannot read property 'split' of undefined
        if(newv===undefined){
            this.ruleForm.lyrics=''
        }
   } ,
   //  避免浏览器报错: Cannot read property 'split' of undefined
   'ruleForm.opernPath'(newv){
    if(newv===undefined){
            this.ruleForm.opernPath=''
        }
   },
}
}
</script>

<style scoped lang="scss">
@import "../../styles/initialization.scss";
@import "../../styles/works";
.border-bottom {
  margin-top: 12px;
  margin-bottom: 30px;
}
.header-title {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: left;
  color: #333333;
}
::v-deep .progress{
  width: 402px;
}
span.success-title{
  width: 260px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  color: #222222;
}
::v-deep .el-form-item__label {
  font-size: 20px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #333333;
}
.el-upload__tip {
  font-size: 10px;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: LEFT;
  color: #aaaaaa;
}
.el-button {
  width: 160px;
  height: 50px;
  margin-top: 10px;
  margin-right: 40px;
  font-size: 14px;
  font-family: PingFang SC, PingFang SC-Medium;
  font-weight: 500;
  line-height: 14px;
  background: #efefef;
  color: #666666;
  border: none;
}
.active {
  background: #ff004d;
  color: #ffffff;
}
div.header_row{
  margin-bottom: 0;
}
.el-row {
    margin-bottom:40px;
}
</style>
